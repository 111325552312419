<template>
    <div class="dashboard__container--body pb-5">
      <Loader v-if="performingRequest" />
      <iframe v-if="showIframe"
        :src="iframaData"
        id="frame"
        >
      </iframe>
      <p class="caption mt-3">*You have been offered a position as an employee of Jump Employee Services LLC. Please complete the onboarding process above.</p>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import firebase from 'firebase/app';
const fb = require('../../../firebaseConfig.js')


export default {
  name: 'employeeOnboarding',
  data: () => ({ 
    iframaData: null,
    showIframe: true,
  }),
  created() {
    this.createComponentSession()
  },
  mounted() {
    this.addEventListenerToIFrame()
  },
  components: {
    Loader,
  },
  computed: {
    ...mapState(['currentUser', 'userProfile']),
    iframe() {
      return this.$el.querySelector("iframe")
    },
  },
  methods: {
    createComponentSession() {
      this.performingRequest = true
      console.log('fetching onboarding')
      const createComponentSession = firebase.functions().httpsCallable('createComponentSessionEmployee')
      createComponentSession({
        id: this.currentUser.uid
      })
      .then(result => {
        if (result && result.data && result.data.url) {
          this.iframaData = result.data.url
          console.log(result.data)
          this.performingRequest = false
        }
      })
    },
    addEventListenerToIFrame() {
      const channel = new MessageChannel();
      const user = this.currentUser
      const store = this.$store
      const router = this.$router
      this.iframe.addEventListener("load", function(event) {
      event.target.contentWindow.postMessage("", "*", [channel.port2]);
        channel.port1.onmessage = (event) => {
          console.log(event.data)
          // if (event.data.eventType == 'WORKER_ONBOARDING_COMPLETE') {
          //   fb.usersCollection.doc(user.uid).update({
          //     evereeOnboardingComplete: true
          //   })
          //   store.dispatch('getUserProfile')
          //   setTimeout(() => {
          //     router.push('/account/payroll/home')
          //   }, 1000)
            
          // } else if (event.data.eventType == 'DISMISS') {
          //   console.log(event.data)
          // }
        };
      })
    }
  },
  beforeDestroy () {
    this.showIframe = false;
    delete this.showIframe;
    this.iframaData = null;
    delete this.iframeData;
  }
}

</script>